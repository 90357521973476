import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-organizations-badges',
  templateUrl: './organizations-badges.component.html',
  styleUrls: ['./organizations-badges.component.scss'],
})
export class OrganizationsBadgesComponent {
  @Input() favoriteOrganizations: { id: string; name: string }[] = [];

  constructor() {}
}
