import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { UserAccountService } from '../global-services/user-account.service';

export const roleBasedGuard: CanActivateFn = async (
  next: ActivatedRouteSnapshot
) => {
  if (
    await inject(UserAccountService).checkUserRoleWithAsyncAwait()
  ) {
    return true;
  } else {
    let tenant = localStorage.getItem('tenant');
    if (tenant) {
      inject(Router).navigate([`admin-login/${tenant}`]);
    } else {
      inject(Router).navigate(['no-tenant-fallback']);
    }

    return false;
  }
};
