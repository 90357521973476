import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tile-skeleton',
  templateUrl: './tile-skeleton.component.html',
  styleUrls: ['./tile-skeleton.component.scss'],
})
export class TileSkeletonComponent {
  @Input() trigger: boolean = false;
  @Input() skeletons: number[] = [];
}
