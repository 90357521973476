import { Injectable } from '@angular/core';
import { from, Observable, of, switchMap } from 'rxjs';
import { IWorkplace } from '../interfaces/i-workplace';
import { HttpClient } from '@angular/common/http';
import { ApiRequestUrlHandlerService } from '../global-services/api-request-url-handler.service';
import { AuthTokenProviderService } from '../global-services/auth-token-provider.service';
import { RequestHeaderProviderService } from '../global-services/request-header-provider.service';
import { environment } from 'src/environments/environment';
import { IWorkplaceQuery } from '../interfaces/i-workplace-query';
import { DataRequestLevel } from '../types/data-request-level';

@Injectable({
  providedIn: 'root',
})
export class WorkplaceRepositoryService {
  constructor(
    private _requestHeaderProvider: RequestHeaderProviderService,
    private _http: HttpClient,
    private _authTokenProvider: AuthTokenProviderService,
    private _apiRequestHandler: ApiRequestUrlHandlerService
  ) {}

  //* POST Methods
  public getWorkplacesByQuery(
    pageSize: number,
    pageNumber: number,
    queryParams: IWorkplaceQuery
  ): Observable<IWorkplace[]> {
    return from(this._authTokenProvider.getCurrentAccessToken()).pipe(
      switchMap((token) => {
        const headers = this._requestHeaderProvider.getXApiHeaders(
          DataRequestLevel.tenant,
          token
        );
        return this._http.post<IWorkplace[]>(
          `${
            environment.api_url.platform_service
          }${this._apiRequestHandler.getDataLevelUrlPart(
            DataRequestLevel.tenant
          )}/mgmt/organizations/query/`,
          queryParams,
          {
            headers,
            params: {
              pageSize: pageSize.toString(),
              pageNumber: pageNumber.toString(),
            },
          }
        );
      })
    );
  }

  public getPublicWorkplacesForTenant(
    pageSize: number,
    pageNumber: number,
    queryParams?: IWorkplaceQuery
  ): Observable<IWorkplace[]> {
    const headers = this._requestHeaderProvider.getXApiHeaders(
      DataRequestLevel.tenant
    );
    return this._http.post<IWorkplace[]>(
      `${environment.api_url.platform_service_public}/organizations/query`,
      queryParams,
      {
        headers,
        params: {
          pageSize,
          pageNumber,
        },
      }
    );
  }

  public createWorkplace(workplace: IWorkplace): Observable<{ organizationId: string }> {
    return from(this._authTokenProvider.getCurrentAccessToken()).pipe(
      switchMap((token) => {
        const headers = this._requestHeaderProvider.getXApiHeaders(
          DataRequestLevel.tenant,
          token
        );
        return this._http.post<any>(
          `${
            environment.api_url.platform_service
          }${this._apiRequestHandler.getDataLevelUrlPart(
            DataRequestLevel.tenant
          )}/mgmt/organizations`,
          workplace,
          { headers }
        );
      })
    );
  }

  public updateWorkplace(
    workplace: IWorkplace,
    code: string
  ): Observable<{ id: string }> {
    return from(this._authTokenProvider.getCurrentAccessToken()).pipe(
      switchMap((token) => {
        const headers = this._requestHeaderProvider.getXApiHeaders(
          DataRequestLevel.tenant,
          token
        );
        return this._http.patch<any>(
          `${
            environment.api_url.platform_service
          }${this._apiRequestHandler.getDataLevelUrlPart(
            DataRequestLevel.tenant
          )}/mgmt/organizations/${code}`,
          workplace,
          { headers }
        );
      })
    );
  }
}
