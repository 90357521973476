import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-notifications-skeleton',
  templateUrl: './notifications-skeleton.component.html',
  styleUrls: ['./notifications-skeleton.component.scss'],
})
export class NotificationsSkeletonComponent {
  @Input() trigger = false;
}
