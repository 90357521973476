import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '../../../global-services/notifications.service';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
})
export class NotificationsListComponent implements OnInit {
  constructor(public notificationsService: NotificationsService) {}

  ngOnInit() {
    // this.notificationsService.getNotifications();
  }
}
