import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
})
export class ModalHeaderComponent implements OnInit, OnDestroy {
  @HostListener('window:popstate', ['$event'])
  dismissModalAuto() {
    this._modalController.dismiss(); // Dismiss the modal if it's open
  }

  @Input() title?: string;

  constructor(private _modalController: ModalController) {}

  dismissModal() {
    this._modalController.dismiss();
  }

  ngOnInit(): void {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal',
    };
    history.pushState(modalState, 'Modal State', '#modal');
  }

  ngOnDestroy() {
    if (window.history.state.modal) {
      history.back();
    }
  }
}
