import { Injectable } from '@angular/core';
import { IPushNotification } from 'src/app/interfaces/i-push-notification';
import { NotificationsRepositoryService } from 'src/app/repositories/notifications-repository.service';
import { NotificationModalComponent } from '../shared/components/notifications-list/partials/notification-modal/notification-modal.component';
import { ModalController } from '@ionic/angular';
import { StorageService } from 'src/app/global-services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  public notifications: IPushNotification[] = [];
  public isLoading = false;
  public newNotificationsCount = 0;
  public notify?: string;

  constructor(
    private _notificationsRepository: NotificationsRepositoryService,
    private _modalController: ModalController,
    private _storageService: StorageService
  ) {}

  async presentModal(notification: IPushNotification): Promise<any> {
    const modal = await this._modalController.create({
      component: NotificationModalComponent,
      componentProps: {
        notification: notification,
      },
    });
    this.markNotificationAsRead(notification);
    return await modal.present();
  }

  public getNotifications(animate?: boolean): void {
    this.isLoading = true;
    this._notificationsRepository
      .getLatestNotifications(
        {
          status: 'LIVE',
          anyAggregatedTaskStatus: ['SENT_SUCCESSFULLY'],
        },
        5,
        0
      )
      .subscribe({
        next: async (resp) => {
          this.notifications = resp;
          this.notifications.sort(
            (a, b) =>
              new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          );
          this.newNotificationsCount =
            this.notifications.length - (await this.markReadNotifications());

          if (animate) {
            this.notify = 'start';
            setTimeout(() => {
              this.notify = 'end';
            });
          }

          this.isLoading = false;
        },
        error: (error) => {
          console.log(JSON.stringify(error));
          this.isLoading = false;
        },
      });
  }

  public async markNotificationAsRead(
    notification: IPushNotification
  ): Promise<void> {
    const notificationsRead: { value: string | null } =
      await this._storageService.getStorageValue('notifications');
    const notificationsReadList: string[] = JSON.parse(
      notificationsRead.value || '[]'
    );
    notificationsReadList.push(notification.id);

    if (notificationsReadList.length > 30) {
      notificationsReadList.pop();
    }

    await this._storageService.setStorage(
      'notifications',
      JSON.stringify(notificationsReadList)
    );

    this.newNotificationsCount =
      this.notifications.length - (await this.markReadNotifications());
  }

  public async markReadNotifications(): Promise<number> {
    let i = 0;

    const notificationsRead: { value: string | null } =
      await this._storageService.getStorageValue('notifications');
    const notificationsReadList: string[] = JSON.parse(
      notificationsRead.value || '[]'
    );
    this.notifications.forEach((n) => {
      if (
        notificationsReadList.find((nR) => {
          return nR === n.id;
        })
      ) {
        i++;
        n.read = true;
      }
    });
    return i;
  }
}
