import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClipboardService } from 'src/app/global-services/clipboard.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-hidden-support',
  templateUrl: './hidden-support.component.html',
  styleUrls: ['./hidden-support.component.scss'],
})
export class HiddenSupportComponent implements OnInit, OnDestroy {
  constructor(public clipboardService: ClipboardService) {}

  isShown = false;
  email = '';

  show() {
    this.isShown = !this.isShown;
  }

  async copy(text: string): Promise<void> {
    await this.clipboardService.writeToClipboard(text);
  }

  ngOnInit(): void {
    this.isShown = false;
    this.email = environment.supportEmail;
  }

  ngOnDestroy(): void {
    this.isShown = false;
  }
}
