import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalLoaderTriggerService {
  private isLoader = false;
  private message = '';

  constructor() {}

  public get IsLoader(): boolean {
    return this.isLoader;
  }

  public get Message(): string {
    return this.message;
  }

  public showLoader(message?: string): void {
    if (message) {
      this.message = message;
    }
    this.isLoader = true;
  }

  public setMessage(message: string): void {
    this.message = message;
  }

  public hideLoader(): void {
    this.message = '';
    this.isLoader = false;
  }
}
