import { trigger, transition, style, animate } from '@angular/animations';
import { Component } from '@angular/core';
import { AdminDataLevelService } from 'src/app/global-services/admin-data-level.service';

@Component({
  selector: 'app-admin-level-instance-selector',
  templateUrl: './admin-level-instance-selector.component.html',
  styleUrls: ['./admin-level-instance-selector.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('250ms ease-in', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('250ms ease-out', style({ opacity: 0 }))]),
    ]),
  ],
})
export class AdminLevelInstanceSelectorComponent {
  constructor(
    public adminDataLevel: AdminDataLevelService,
  ) {}

}
