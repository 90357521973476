import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { GlobalLoaderTriggerService } from 'src/app/global-services/global-loader-trigger.service';


@Component({
  selector: 'app-global-loader',
  templateUrl: './global-loader.component.html',
  styleUrls: ['./global-loader.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('150ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('500ms ease-out', style({ opacity: 0 }))
      ])
    ])
  ]

})
export class GlobalLoaderComponent implements OnInit {

  constructor(public globalLoaderTrigger: GlobalLoaderTriggerService) { }

  ngOnInit() { }

}
