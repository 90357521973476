<ion-header>
  <ion-toolbar>
    <ion-title>Regulamin</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <h3 class="legalLockHeader">
    Aby korzystać z konta, musisz zaakceptować regulamin oraz politykę
    prywatności
  </h3>
  <h4>Polityka prywatności</h4>
  <a [href]="policyUrl" target="_blank"
    >Polityka prywatności aplikacji<ion-icon name="open-outline"></ion-icon
  ></a>
  <h4>Regulamin</h4>
  <div class="legalLockContent" [innerHTML]="terms?.terms | sanitizeDOM"></div>
  <br />
  <ion-button color="success" (click)="confirm()"
    ><ion-icon slot="start" name="checkmark-outline"></ion-icon
    >Akceptuję</ion-button
  >
  <ion-button color="danger" (click)="decline()"
    ><ion-icon slot="start" name="close-outline"></ion-icon>Odrzuć</ion-button
  >
</ion-content>
