import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { forkJoin, Observable } from 'rxjs';
import { AlertService } from 'src/app/global-services/alert.service';
import { AuthenticationService } from 'src/app/global-services/authentication.service';
import { GlobalLoaderTriggerService } from 'src/app/global-services/global-loader-trigger.service';
import { VersionService } from 'src/app/global-services/version.service';
import { ITerms } from 'src/app/interfaces/i-terms';
import { IUserDetails } from 'src/app/interfaces/i-user-details';
import { MetadataRepositoryService } from 'src/app/repositories/metadata-repository.service';
import { UserRepositoryService } from 'src/app/repositories/user-repository.service';
import { DataRequestLevel } from 'src/app/types/data-request-level';

@Component({
  selector: 'app-legal-lock',
  templateUrl: './legal-lock.component.html',
  styleUrls: ['./legal-lock.component.scss'],
})
export class LegalLockComponent implements OnInit {
  @Input() myself?: IUserDetails;

  constructor(
    private _modalController: ModalController,
    private _metadataRepository: MetadataRepositoryService,
    private _userRepository: UserRepositoryService,
    private _authenticationService: AuthenticationService,
    private _globalLoader: GlobalLoaderTriggerService,
    private _alertService: AlertService,
    public versionService: VersionService
  ) {}

  public isRequestLoading = false;
  public terms?: ITerms;
  public policyUrl?: string;

  ngOnInit(): void {
    this.setTerms();
  }

  public setTerms() {
    const requests: Observable<any>[] = [];

    requests.push(
      this._metadataRepository.getMetadata(
        'tenant_terms_conditions',
        DataRequestLevel.tenant
      )
    );
    requests.push(
      this._metadataRepository.getMetadata(
        'tenant_policy_url',
        DataRequestLevel.tenant
      )
    );

    forkJoin(requests).subscribe({
      next: (resp) => {
        this.terms = resp[0]?.payload;
        this.policyUrl = resp[1]?.payload?.url;
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  confirm() {
    if (this.myself) {
      this._globalLoader.showLoader();
      this.myself.termsOfUseAccepted = true;
      this._userRepository.updateUser(this.myself).subscribe({
        next: () => {
          this._globalLoader.hideLoader();
          this._modalController.dismiss('confirm');
        },
        error: (error) => {
          this._alertService.presentAlert(
            'Komunikat',
            '',
            'Nie udało się zaktualizować danych użytkownika',
            [{ text: 'OK' }],
            () => {}
          );
          this._globalLoader.hideLoader();
        },
      });
    }
  }

  decline() {
    this._authenticationService.logoutWithAmplify();
    this._modalController.dismiss('decline');
  }
}
