<ion-segment [value]="dataLevelService.CurrentLevel" (ionChange)="dataLevelService.onLevelChange($event)">
  <ion-segment-button
    [value]="level"
    *ngFor="let level of dataLevelService.AvailableLevels"
    [id]="level"
  >
    <ion-label>{{ ('data_levels.' + getLevelKey(level)) | translate }}</ion-label>
  </ion-segment-button>
</ion-segment>

