<ion-app>
  <!-- <ion-split-pane contentId="main-content">
    <ion-menu
      contentId="main-content"
      type="overlay"
      *ngIf="userAccountService.IsAuthorized"
    >
      <ion-content>
        <ion-list id="inbox-list" class="menuList">
          <div class="imageContainer">
            <img
              [src]="'./assets/static_images/logos/company_logo.png'"
              class="cardImage"
            />
          </div>
          <ion-menu-toggle
            auto-hide="false"
            *ngFor="let p of appPages; let i = index"
          >
            <div>
              <h4 *ngIf="p.isDivider && p.groupName">{{ p.groupName }}</h4>
              <ion-item
                [class.paragraph__item]="p.isParagraph"
                [class.divider__item]="p.isDivider"
                routerDirection="root"
                [routerLink]="[p.url]"
                lines="none"
                detail="false"
                routerLinkActive="selected"
              >
                <ion-icon
                  [class.paragraph__icon]="p.isParagraph"
                  aria-hidden="true"
                  slot="start"
                  [ios]="p.icon + '-outline'"
                  [md]="p.icon + '-sharp'"
                ></ion-icon>
                <ion-label>{{ p.title | translate }}</ion-label>
              </ion-item>
            </div>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane> -->

  <ion-content>
    <ion-router-outlet id="main-content"></ion-router-outlet
  ></ion-content>
  <app-footer></app-footer>
</ion-app>

<app-global-loader></app-global-loader>
