<ion-footer>
  <ion-toolbar style="padding-left: 1%; padding-right: 1%">
    <ion-title>
      <div class="platformInfo">
        <span (click)="reload()">
          <ion-icon name="desktop-outline"></ion-icon>
          &nbsp;
          {{ versionService.platformVersion }}&nbsp;
        </span>
        <span *ngIf="userAccountService.IsAuthorized">
          &nbsp; {{ userAccountService.UserDetails?.displayName }}</span
        >
      </div>
    </ion-title>

    <ion-buttons slot="end" *ngIf="userAccountService.IsAuthorized">
      <ion-button fill="solid" (click)="logout()" color="secondary">
        <ion-icon name="exit-outline"></ion-icon
        ><span *ngIf="isWideScreen">&nbsp;Wyloguj</span>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
