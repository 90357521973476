import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-universal-header-subpage',
  templateUrl: './universal-header-subpage.component.html',
  styleUrls: ['./universal-header-subpage.component.scss'],
})
export class UniversalHeaderSubpageComponent {
  @Input() title?: string;
}
