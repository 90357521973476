import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataRequestLevel } from '../types/data-request-level';

@Injectable({
  providedIn: 'root',
})
export class DataLevelService {
  private levelChanged? = new BehaviorSubject<DataRequestLevel>(
    DataRequestLevel.tenant
  );
  public levelChanged$?: Observable<DataRequestLevel> =
    this.levelChanged?.asObservable();

  private availableLevels: DataRequestLevel[] = [
    DataRequestLevel.tenant,
    DataRequestLevel.organization,
  ];

  private defaultLevel: DataRequestLevel = DataRequestLevel.tenant;
  private currentLevel: DataRequestLevel = this.defaultLevel;

  public get CurrentLevel(): DataRequestLevel {
    return this.currentLevel;
  }

  public get AvailableLevels(): DataRequestLevel[] {
    return this.availableLevels;
  }

  public get DefaultLevel(): DataRequestLevel {
    return this.defaultLevel;
  }

  public setDefaultLevel(): void {
    this.currentLevel = this.defaultLevel;
  }

  public setSelectedLevel(level: DataRequestLevel): void {
    this.currentLevel = level;
  }

  public onLevelChange(event: any): void {
    this.setSelectedLevel(event?.detail?.value);
    this.levelChanged?.next(event?.detail?.value);
  }
}
