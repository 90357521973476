import { Component, Input } from '@angular/core';
import { IPushNotification } from 'src/app/interfaces/i-push-notification';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
})
export class NotificationModalComponent {
  constructor() {}

  @Input() notification?: IPushNotification;
}
