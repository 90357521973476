import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-image-skeleton',
  templateUrl: './image-skeleton.component.html',
  styleUrls: ['./image-skeleton.component.scss'],
})
export class ImageSkeletonComponent {
  @Input() src: string = '';
  @Input() alt: string = '';
  @Input() fallbackSrc: string = './assets/static_images/fallback/image.svg';
  @Input() spinnerName: string = 'dots';
  @Input() rounded?: number;

  loading = true;

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  handleError() {
    this.loading = false;
    this.src = this.fallbackSrc;
  }
}
