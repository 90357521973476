import { Injectable } from '@angular/core';
import { UserAccountService } from './user-account.service';
import { Router } from '@angular/router';
import { signOut } from 'aws-amplify/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private _userAccountService: UserAccountService,
    private _router: Router
  ) {}

  public async logoutWithAmplify(): Promise<void> {
    await signOut({ global: true });
    this.clearCognitoStorage();
    this._userAccountService.resetUserData();

    this._router.navigateByUrl('no-tenant-fallback').then(() => {
      window.location.reload();
    });
  }

  private clearCognitoStorage(): void {
    Object.keys(localStorage).forEach((key) => {
      if (key.includes('CognitoIdentityServiceProvider')) {
        localStorage.removeItem(key);
      }
    });
  }

  public loginWithAmplify(): void {
    this._router.navigateByUrl('login');
  }
}
