<div class="noOrganizationsPlaceholder">
  <h4>Dodaj organizację do ulubionych</h4>
  <p>
    To miejsce, w którym będą wyświetlane wiadomości od Twoich ulubionych organizacji
  </p>
  <ion-button [routerLink]="'/folder/workplaces'"
    ><ion-icon slot="start" name="bookmark"></ion-icon>Dodaj
    organizację</ion-button
  >
</div>
