<ion-segment
  [value]="adminDataLevelService.CurrentLevel"
  (ionChange)="adminDataLevelService.onLevelChange($event)"
>
  <ion-segment-button
    [value]="level"
    *ngFor="let level of adminDataLevelService.AvailableLevels()"
    [id]="level"
  >
    <ion-label>{{ getLevelKey(level) }}</ion-label>
  </ion-segment-button>
</ion-segment>
