import { Injectable } from '@angular/core';
import { Clipboard } from '@capacitor/clipboard';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {

  public isToast = false;

  public async writeToClipboard(string: string): Promise<void> {
    try {
      await Clipboard.write({
        string,
      });
    } catch (error) {
      console.error(error);
    }
  }

  showToast(isOpen: boolean): void {
    this.isToast = isOpen;
  }

  closeToast(): void {
    this.isToast = false;
  }
}