import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RedirectionByPlatform implements Resolve<void> {
  constructor(private _router: Router) {}

  resolve(): void {
    let tenant = localStorage.getItem('tenant');
    if (tenant) {
      this._router.navigate([`admin-login/${tenant}`]);
    } else {
      this._router.navigate(['no-tenant-fallback']);
    }
  }
}
