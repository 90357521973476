import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  public appName: string = 'App';
  public appSubtitle: string = 'App Subtitle';
  public legal: string = 'legal';
  public platformVersion = '';
  public platform?: 'android' | 'ios' | 'web';
  public tenantName = '';

  constructor(private _platform: Platform) {
    this.appName = environment.appName;
    this.appSubtitle = environment.appSubtitle;
    this.legal = environment.legal;

    if (this._platform.is('android')) {
      this.platformVersion = environment.mobileAppVersions.android;
      this.platform = 'android';
    } else if (this._platform.is('ios')) {
      this.platformVersion = environment.mobileAppVersions.ios;
      this.platform = 'ios';
    } else {
      this.platformVersion = environment.version;
      this.platform = 'web';
    }
  }
}
