import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICognitoConfig } from '../interfaces/i-cognito-config';
import { RequestHeaderProviderService } from './request-header-provider.service';
import { Observable } from 'rxjs';
import { DataRequestLevel } from '../types/data-request-level';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TenantRepositoryService {
  constructor(
    private _http: HttpClient,
    private _requestHeaderProvider: RequestHeaderProviderService
  ) {}

  public getTenantInfo(key: string): Observable<ICognitoConfig> {
    const headers = this._requestHeaderProvider.getXApiHeaders(
      DataRequestLevel.tenant
    );
    return this._http.get<ICognitoConfig>(
      `${environment.api_url.platform_service_public}/tenant/tenant-code/${key}`,
      { headers }
    );
  }
}
