<app-universal-header
  [title]="'admin-login' | translate"
></app-universal-header>

<ion-content [fullscreen]="true" class="image-background">
  <ion-card  class="backgroundTransparent containerWithMargins">
    <ion-card-header>{{"admin_pages.login" | translate}}</ion-card-header>
    <ion-card-content class="cardCentered">
      <h1>{{activeTenant.activeTenant?.friendlyName}}</h1>
      <ion-icon name="cog" class="adminCardIcon"></ion-icon>
      <div>
        <ion-button *ngIf="!IsAuthenticated" (click)="login()">
          <ion-icon slot="start" name="log-in-outline"></ion-icon
          >{{"buttons.login" | translate}}
        </ion-button>
      </div>
      <div *ngIf="IsAuthenticated">
        <ion-button (click)="openPanel()" color="primary">
          <ion-icon slot="start" name="enter-outline"></ion-icon
          >{{"admin_pages.open_panel" | translate}}
        </ion-button>
        <ion-list>
          <ion-item lines="none">
            <ion-buttons slot="start"
              >{{"admin_pages.logged_in_as" | translate}}</ion-buttons
            >
            <ion-buttons slot="end">
              <ion-button (click)="logout()" color="primary">
                <ion-icon slot="start" name="log-out-outline"></ion-icon
                >{{"buttons.logout" | translate}}
              </ion-button></ion-buttons
            >
          </ion-item>
          <ion-item lines="none">
            <ion-icon name="person-outline"></ion-icon>&nbsp;
            <span *ngIf="!isInEdit" class="colFlex">
              <b *ngIf="UserDetails?.displayName"
                >{{UserDetails?.displayName}}</b
              >
              <i *ngIf="!UserDetails?.displayName">Brak nazwy użytkownika</i>

              &nbsp;
              <ion-button (click)="switchEdit()" color="secondary" size="small"
                ><ion-icon name="pencil"></ion-icon>&nbsp;Edytuj</ion-button
              >
            </span>
            <span *ngIf="isInEdit">
              <ion-input
                label="Nazwa użytkownika"
                [placeholder]="'Wprowadź nazwę'"
                fill="outline"
                required="true"
                errorText="Musisz wprowadzić nazwę"
                labelPlacement="floating"
                [(ngModel)]="newDisplayName"
              ></ion-input>
              <ion-button (click)="update()" color="primary" size="small"
                ><ion-icon name="checkmark"></ion-icon>&nbsp;Zapisz</ion-button
              >
              <ion-button (click)="switchEdit()" color="secondary" size="small"
                ><ion-icon name="close"></ion-icon>&nbsp;Anuluj</ion-button
              >
            </span>
          </ion-item>
          <ion-item lines="none"
            ><ion-icon name="mail-outline"></ion-icon
            >&nbsp;{{UserDetails?.email}}</ion-item
          >
          <ion-item lines="none"
            ><ion-icon name="location-outline"></ion-icon>&nbsp;<b
              >{{UserDetails?.activeTenant}}</b
            ></ion-item
          >
          <div
            *ngIf="(UserDetails?.availableTenants?.length || 0) > 1"
            class="warningText"
          >
            <ion-item lines="none"
              ><ion-icon name="trail-sign-outline"></ion-icon
              >&nbsp;Multi-tenant</ion-item
            >

            <ion-item *ngFor="let tenant of UserDetails?.availableTenants"
              >{{tenant}}</ion-item
            >
            <br />
          </div>
        </ion-list>
      </div>
    </ion-card-content>
  </ion-card>
</ion-content>
