<app-modal-header [title]="fileName"></app-modal-header>
<ion-content [fullscreen]="true">
  <pinch-zoom
    style="height: 100%; width: 100%; background-color: white;"
  >
    <div [ngSwitch]="fileType" style="height: 100%; width: 100%">
      <img
        *ngSwitchCase="'image/png'"
        [src]="fileUrl"
        alt="Enlarged Image"
        class="idCardModal__code"
      />
      <img
        *ngSwitchCase="'image/jpeg'"
        [src]="fileUrl"
        alt="Enlarged Image"
        class="idCardModal__code"
      />

      <div *ngSwitchCase="'application/pdf'" style="height: 100%; width: 100%">
        <ion-spinner
          style="position: fixed; top: 15vh; left: 45vw"
          *ngIf="ShowLoader"
        ></ion-spinner>

        <pdf-viewer
          [src]="fileUrl"
          [render-text]="true"
          [original-size]="false"
          style="height: 100%; width: 100%"
          (after-load-complete)="onPdfLoad()"
        ></pdf-viewer>
      </div>
      <video
        controls
        loop
        preload="auto"
        webkit-playsinline
        playsinline
        height="100%"
        width="100%"
        *ngSwitchCase="'video/mp4'"
        [src]="fileUrl"
        type="video/mp4"
      ></video>
    </div>
  </pinch-zoom>
</ion-content>
