import { Injectable } from '@angular/core';
import { Amplify } from 'aws-amplify';
import { ResourcesConfig } from '@aws-amplify/core';
import { environment } from 'src/environments/environment';
import { TenantRepositoryService } from './tenant-repository.service';
import { ICognitoConfig } from '../interfaces/i-cognito-config';
import { firstValueFrom } from 'rxjs';
import { translations } from '@aws-amplify/ui-angular';
import { I18n } from 'aws-amplify/utils';
import { Router } from '@angular/router';
import { VersionService } from './version.service';

I18n.putVocabularies(translations);
I18n.setLanguage('pl');
I18n.putVocabulariesForLanguage('pl', {
  'Account recovery requires verified contact information':
    'Odzyskiwanie konta wymaga zweryfikowanych danych kontaktowych',
  'Add your Profile': 'Dodaj swój profil',
  'Add your Website': 'Dodaj swoją stronę internetową',
  'Back to Sign In': 'Powrót do logowania',
  'Change Password': 'Zmień hasło',
  Changing: 'Zmienianie',
  Code: 'Kod',
  'Confirm Password': 'Potwierdź hasło',
  'Confirm Sign Up': 'Potwierdź rejestrację',
  'Confirm SMS Code': 'Potwierdź kod SMS',
  'Confirm TOTP Code': 'Potwierdź kod TOTP',
  Confirm: 'Potwierdź',
  'Confirmation Code': 'Kod potwierdzający',
  Confirming: 'Potwierdzanie',
  'Create a new account': 'Utwórz nowe konto',
  'Create Account': 'Utwórz konto',
  'Creating Account': 'Tworzenie konta',
  'Dismiss alert': 'Zamknij ostrzeżenie',
  Email: 'Adres e-mail',
  'Enter your Birthdate': 'Wprowadź swoją datę urodzenia',
  'Enter your code': 'Wprowadź swój kod',
  'Enter your Confirmation Code': 'Wprowadź swój kod potwierdzający',
  'Enter your Email': 'Wprowadź swój adres e-mail',
  'Enter your Family Name': 'Wprowadź swoje nazwisko rodowe',
  'Enter your Given Name': 'Wprowadź swoje imię',
  'Enter your Middle Name': 'Wprowadź swoje drugie imię',
  'Enter your Name': 'Wprowadź swoje nazwisko',
  'Enter your Nickname': 'Wprowadź swoją nazwę',
  'Enter your Password': 'Wprowadź swoje hasło',
  'Enter your phone number': 'Wprowadź swój numer telefonu',
  'Enter your Preferred Username': 'Wprowadź preferowaną nazwę użytkownika',
  'Enter your username': 'Wprowadź swój adres e-mail',
  'Forgot password?': 'Zapomniałeś hasła?',
  'Forgot your password?': 'Zapomniałeś swojego hasła?',
  'Hide password': 'Ukryj hasło',
  'It may take a minute to arrive': 'To może potrwać chwilę',
  Loading: 'Ładowanie',
  'New password': 'Nowe hasło',
  or: 'lub',
  Password: 'Hasło',
  'Phone Number': 'Numer telefonu',
  'Please confirm your Password': 'Proszę potwierdź swoje hasło',
  'Resend Code': 'Wyślij kod ponownie',
  'Reset your password': 'Zresetuj swoje hasło',
  'Reset your Password': 'Zresetuj swoje hasło',
  'Send code': 'Wyślij kod',
  'Send Code': 'Wyślij Kod',
  Sending: 'Wysyłanie',
  'Setup TOTP': 'Konfiguracja TOTP',
  'Show password': 'Pokaż hasło',
  'Sign in to your account': 'Zaloguj się do swojego konta',
  'Sign In with Amazon': 'Zaloguj się przez Amazon',
  'Sign In with Apple': 'Zaloguj się przez Apple',
  'Sign In with Facebook': 'Zaloguj się przez Facebooka',
  'Sign In with Google': 'Zaloguj się przez Google',
  'Sign in': 'Zaloguj się',
  'Sign In': 'Zaloguj się',
  'Signing in': 'Logowanie',
  Skip: 'Pomiń',
  Submit: 'Zatwierdź',
  Submitting: 'Przesyłanie',
  Username: 'Nazwa użytkownika',
  'Verify Contact': 'Zweryfikuj dane kontaktowe',
  Verify: 'Zweryfikuj',
  'We Emailed You': 'Wysłaliśmy Ci wiadomość e-mail',
  'We Sent A Code': 'Wysłaliśmy Ci kod',
  'We Texted You': 'Wysłaliśmy Ci wiadomość',
  'Your code is on the way. To log in, enter the code we emailed to':
    'Wysłaliśmy Ci kod. Aby się zalogować, wprowadź kod, który otrzymałeś na powiązany adres e-mail',
  'Your code is on the way. To log in, enter the code we sent you':
    'Wysłaliśmy Ci kod. Aby się zalogować, wprowadź otrzymany kod',
  'Your code is on the way. To log in, enter the code we texted to':
    'Wysłaliśmy Ci kod. Aby się zalogować, wprowadź kod, który otrzymałeś w wiadomości',
  'Incorrect username or password': 'Błędne hasło lub adres e-mail',
  'Invalid password format':
    'Nieprawidłowy format hasła (hasło powinno zawierać: dużą literę, małą literę, cyfrę, znak specjalny)',
  'Invalid phone number format': 'Nieprawidłowy format numeru telefonu',
  'Lost your code? ': 'Czy utraciłeś kod weryfikacyjny? ',
  'Network error': 'Błąd sieci',
  'New Password': 'Nowe hasło',
  'No account? ': 'Czy nie masz konta?',
  'User already exists': 'Użytkownik już istnieje',
  'User does not exist': 'Użytkownik nie istnieje',
  'Username cannot be empty': 'Wprowadź adres e-mail',
  'Username/client id combination not found.': 'Nie znaleziono konta',
  'An account with the given email already exists.':
    'Istnieje już konto powiązane z podanym adresem e-mail',
  'Confirm a Code': 'Potwierdź kod',
  'Confirm Sign In': 'Potwierdź logowanie',
  'Create account': 'Utwórz konto',
  'Password must contain at least one lowercase letter':
    'Hasło powinno zawierać co najmniej jedną małą literę',
  'Password attempts exceeded': 'Przekroczono maksymalną ilość prób logowania',
  'Incorrect username or password.': 'Nieprawidłowy adres e-mail lub hasło',
});

@Injectable({
  providedIn: 'root',
})
export class AmplifyConfiguratorService {
  constructor(
    private _tenantRepository: TenantRepositoryService,
    private _router: Router,
    private _versionService: VersionService
  ) {}

  public configured = false;

  public updateAmplifyConfig(newConfig: ResourcesConfig): void {
    this.configureAmplify(newConfig);
  }

  public async loadInitialAmplifyConfig(): Promise<void> {
    const tenant = localStorage.getItem('tenant');
    if (tenant) {
      try {
        const config: ICognitoConfig = await firstValueFrom(
          this._tenantRepository.getTenantInfo(tenant)
        );
        this._versionService.tenantName = config.friendlyName;
        this.updateAmplifyConfig({
          Auth: {
            Cognito: {
              userPoolClientId: config.appClientId,
              userPoolId: config.userPoolId,
              loginWith: {
                oauth: {
                  domain: environment.cognito.oauth_domain,
                  scopes: environment.cognito.oauth_scopes,
                  redirectSignIn: environment.cognito.oauth_redirect_sign_in,
                  redirectSignOut: environment.cognito.oauth_redirect_sign_out,
                  responseType: environment.cognito.oauth_response_type,
                },
              },
            },
          },
        });
        this.configured = true;
      } catch (error) {
        console.error(error);
      }
    } else {
      this._router.navigateByUrl('no-tenant-fallback');
    }
  }

  private configureAmplify(config: ResourcesConfig): void {
    Amplify.configure(config);
  }
}
