<ion-header translucent="true">
  <ion-toolbar>
    <ion-title class="headerWithLogo">
      <span *ngIf="versionService.tenantName">{{
        versionService.tenantName + " - "
      }}</span
      >{{ title }}
    </ion-title>
  </ion-toolbar>
</ion-header>
