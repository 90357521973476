import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular'

@Injectable({
  providedIn: 'root'
})
export class PlatformInformationService {

  constructor(private _platform: Platform) { }

  public get IsMobilePlatform(): boolean {
    return this._platform.is('ios') || this._platform.is('android');
  }

  public get IsAndroid(): boolean {
    return this._platform.is('android');
  }
}
