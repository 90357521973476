import { Injectable } from '@angular/core';
import { ICognitoConfig } from '../interfaces/i-cognito-config';

@Injectable({
  providedIn: 'root',
})
export class ActiveTenantService {
  constructor() {}

  public activeTenant?: ICognitoConfig;
}
