<div class="ion-padding">
  <div
    class="adminDropdownContainer"
    *ngIf="adminDataLevel.CurrentLevel === 'platform'"
  >
    <ion-select
      label="Region"
      placeholder="Wybierz region"
      [(ngModel)]="adminDataLevel.selectedRegion"
      fill="outline"
    >
      <ion-select-option
        *ngFor="let region of adminDataLevel.availableRegions"
        [value]="region"
        >{{ region.name }}</ion-select-option
      ></ion-select
    >
  </div>
  <div
    *ngIf="adminDataLevel.CurrentLevel === 'organization'"
    class="adminDropdownContainer"
  >
    <ion-select
      *ngIf="(adminDataLevel.availableWorkplaces?.length || 0) > 1"
      fill="outline"
      placeholder="Wybierz organizację"
      [(ngModel)]="adminDataLevel.selectedWorkplaceCode"
      (ngModelChange)="
        adminDataLevel.onWorkplaceChange(adminDataLevel.selectedWorkplaceCode)
      "
    >
      <ion-select-option
        [disabled]="workplace.status === 'STOPPED'"
        [value]="workplace.organizationCode"
        *ngFor="let workplace of adminDataLevel.availableWorkplaces"
        >{{ workplace.friendlyName }}</ion-select-option
      ></ion-select
    >
    <!-- <h3
      class="adminDropdownStaticText"
      *ngIf="
        ((adminDataLevel.availableWorkplaces &&
          adminDataLevel.availableWorkplaces?.length) ||
          0) === 1
      "
    >
      {{ adminDataLevel.selectedWorkplace?.friendlyName }}
    </h3> -->
  </div>
</div>
