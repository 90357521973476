<div style="width: 100%; text-align: center">
  <ion-card>
    <ion-card-header>
      <ion-card-title>Nie masz uprawnień do tych treści</ion-card-title>
      <br />
    </ion-card-header>

    <ion-card-content>
      <br />
      <div>
        <ion-icon class="banIcon" name="ban-outline"></ion-icon>
      </div>
      <br />
      Wygląda na to, że nie przyznano Ci uprawnień do funkcjonalności Panelu
      Administratora.

      <br />

      <b
        >Jeżeli uważasz, że to pomyłka - skontaktuj się z Administratorem wskazanym
        w Polityce prywatności aplikacji lub wsparciem technicznym GGSP</b
      >
      <app-hidden-support></app-hidden-support>
    </ion-card-content>
  </ion-card>
</div>
