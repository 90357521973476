import { Injectable } from '@angular/core';
import { Observable, from, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IUserDetails } from '../interfaces/i-user-details';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AuthTokenProviderService } from '../global-services/auth-token-provider.service';
import { IUserQuery } from '../interfaces/i-user-query';
import { IUser } from '../interfaces/i-user';
import { RequestHeaderProviderService } from '../global-services/request-header-provider.service';
import { IUserWithDetails } from '../interfaces/i-user-with-details';
import { UserActivityStatus } from '../types/user-activity-status';
import { DataRequestLevel } from '../types/data-request-level';
import { ApiRequestUrlHandlerService } from '../global-services/api-request-url-handler.service';
import { ISecurityRole } from '../interfaces/i-security-role';
import { IUserToAdd } from '../interfaces/i-user-to-add';
import { IAccessControlDetails } from '../interfaces/i-access-control-details';
import { IUserToAssign } from '../interfaces/i-user-to-assign';
import { IRolesUpdateRequest } from '../interfaces/i-roles-update-request';

@Injectable({
  providedIn: 'root',
})

//* GET methods
export class UserRepositoryService {
  constructor(
    private _http: HttpClient,
    private _authTokenProvider: AuthTokenProviderService,
    private _requestHeaderProvider: RequestHeaderProviderService,
    private _apiRequestHandler: ApiRequestUrlHandlerService
  ) {}

  public whoAmI(): Observable<IUserDetails> {
    return from(this._authTokenProvider.getCurrentAccessToken()).pipe(
      switchMap((token) => {
        const headers = this._requestHeaderProvider.getXApiHeaders(
          DataRequestLevel.none,
          token
        );
        return this._http.get<IUserDetails>(
          environment.api_url.platform_service + '/whoami/user-info',
          { headers }
        );
      })
    );
  }

  public whatsMyAccess(): Observable<IAccessControlDetails> {
    return from(this._authTokenProvider.getCurrentAccessToken()).pipe(
      switchMap((token) => {
        const headers = this._requestHeaderProvider.getXApiHeaders(
          DataRequestLevel.none,
          token
        );
        return this._http.get<IAccessControlDetails>(
          environment.api_url.platform_service + '/whoami/access-control',
          { headers }
        );
      })
    );
  }

  public whatsMyOrganizations(): Observable<any> {
    return from(this._authTokenProvider.getCurrentAccessToken()).pipe(
      switchMap((token) => {
        const headers = this._requestHeaderProvider.getXApiHeaders(
          DataRequestLevel.none,
          token
        );
        return this._http.get<any>(
          environment.api_url.platform_service + '/whoami/organizations',
          { headers }
        );
      })
    );
  }

  public getUserDetailed(username: string): Observable<IUserWithDetails> {
    return from(this._authTokenProvider.getCurrentAccessToken()).pipe(
      switchMap((token) => {
        const headers = this._requestHeaderProvider.getXApiHeaders(
          DataRequestLevel.tenant,
          token
        );
        return this._http.get<IUserWithDetails>(
          `${
            environment.api_url.platform_service
          }${this._apiRequestHandler.getDataLevelUrlPart(
            DataRequestLevel.tenant
          )}/users/detailed-users/username/${username}`,
          {
            headers,
          }
        );
      })
    );
  }

  public getAllRoles(): Observable<ISecurityRole[]> {
    return from(this._authTokenProvider.getCurrentAccessToken()).pipe(
      switchMap((token) => {
        const headers = this._requestHeaderProvider.getXApiHeaders(
          DataRequestLevel.tenant,
          token
        );
        return this._http.get<ISecurityRole[]>(
          `${
            environment.api_url.platform_service
          }${this._apiRequestHandler.getDataLevelUrlPart(
            DataRequestLevel.tenant
          )}/users/access-control/security-roles/`,
          { headers }
        );
      })
    );
  }

  public getUsersBySecurityRole(
    roleId: string,
    dataLevel: DataRequestLevel,
    code?: string
  ): Observable<IUser[]> {
    return from(this._authTokenProvider.getCurrentAccessToken()).pipe(
      switchMap((token) => {
        const headers = this._requestHeaderProvider.getXApiHeaders(
          DataRequestLevel.tenant,
          token
        );
        const url =
          dataLevel === DataRequestLevel.organization
            ? `${
                environment.api_url.platform_service
              }${this._apiRequestHandler.getDataLevelUrlPart(
                DataRequestLevel.tenant
              )}/users/access-control/organizations/organizationCode/${code}/security-roles/role-code/${roleId}`
            : `${
                environment.api_url.platform_service
              }${this._apiRequestHandler.getDataLevelUrlPart(
                DataRequestLevel.tenant
              )}/users/access-control/security-roles/role-code/${roleId}`;

        return this._http.get<IUser[]>(url, {
          headers,
        });
      })
    );
  }

  public getUsersByQuery(
    pageSize: number,
    pageNumber: number,
    userQuery: IUserQuery,
    level: DataRequestLevel,
    organizationCode?: string
  ): Observable<IUser[]> {
    return from(this._authTokenProvider.getCurrentAccessToken()).pipe(
      switchMap((token) => {
        const headers = this._requestHeaderProvider.getXApiHeaders(
          DataRequestLevel.tenant,
          token
        );

        const url =
          level === DataRequestLevel.tenant
            ? `${
                environment.api_url.platform_service
              }${this._apiRequestHandler.getDataLevelUrlPart(
                DataRequestLevel.tenant
              )}/users/query`
            : `${
                environment.api_url.platform_service
              }${this._apiRequestHandler.getDataLevelUrlPart(
                DataRequestLevel.tenant
              )}/users/organizations/organizationCode/${organizationCode}/query`;
        return this._http.post<IUser[]>(url, userQuery, {
          headers,
          params: {
            pageSize: pageSize.toString(),
            pageNumber: pageNumber.toString(),
          },
        });
      })
    );
  }

  //* POST methods
  public getUsersByAccessControlQuery(
    pageSize: number,
    pageNumber: number,
    userQuery: IUserQuery
  ): Observable<IUser[]> {
    return from(this._authTokenProvider.getCurrentAccessToken()).pipe(
      switchMap((token) => {
        const headers = this._requestHeaderProvider.getXApiHeaders(
          DataRequestLevel.tenant,
          token
        );

        return this._http.post<IUser[]>(
          `${
            environment.api_url.platform_service
          }${this._apiRequestHandler.getDataLevelUrlPart(
            DataRequestLevel.tenant
          )}/users/access-control/query-by-access-control/`,
          userQuery,
          {
            headers,
            params: {
              pageSize: pageSize.toString(),
              pageNumber: pageNumber.toString(),
            },
          }
        );
      })
    );
  }

  public addUserToTenant(user: IUserToAdd): Observable<HttpResponse<any>> {
    return from(this._authTokenProvider.getCurrentAccessToken()).pipe(
      switchMap((token) => {
        const headers = this._requestHeaderProvider.getXApiHeaders(
          DataRequestLevel.tenant,
          token
        );
        return this._http.post<any>(
          `${
            environment.api_url.platform_service
          }${this._apiRequestHandler.getDataLevelUrlPart(
            DataRequestLevel.tenant
          )}/users`,
          user,
          { headers }
        );
      })
    );
  }

  public addUserToOrganization(
    user: IUserToAssign,
    code: string
  ): Observable<HttpResponse<any>> {
    return from(this._authTokenProvider.getCurrentAccessToken()).pipe(
      switchMap((token) => {
        const headers = this._requestHeaderProvider.getXApiHeaders(
          DataRequestLevel.tenant,
          token
        );
        return this._http.post<any>(
          `${
            environment.api_url.platform_service
          }${this._apiRequestHandler.getDataLevelUrlPart(
            DataRequestLevel.tenant
          )}/users/organizations/organizationCode/${code}`,
          user,
          { headers }
        );
      })
    );
  }

  public resendInvitation(userId: string): Observable<HttpResponse<any>> {
    return from(this._authTokenProvider.getCurrentAccessToken()).pipe(
      switchMap((token) => {
        const headers = this._requestHeaderProvider.getXApiHeaders(
          DataRequestLevel.tenant,
          token
        );
        return this._http.post<any>(
          `${
            environment.api_url.platform_service
          }${this._apiRequestHandler.getDataLevelUrlPart(
            DataRequestLevel.tenant
          )}/users/resend-invitation/username/${userId}`,
          null,
          { headers }
        );
      })
    );
  }

  public updateUserActivityStatus(
    userId: string,
    status: UserActivityStatus
  ): Observable<HttpResponse<any>> {
    return from(this._authTokenProvider.getCurrentAccessToken()).pipe(
      switchMap((token) => {
        const headers = this._requestHeaderProvider.getXApiHeaders(
          DataRequestLevel.tenant,
          token
        );

        const body: { targetStatus: UserActivityStatus } = {
          targetStatus: status,
        };

        return this._http.post<any>(
          environment.api_url.platform_service +
            '/user-service/bff/persons/change-activation-status/' +
            userId,
          body,
          { headers }
        );
      })
    );
  }

  public updateUserRoles(roles: IRolesUpdateRequest, userId: string): Observable<HttpResponse<any>> {
    return from(this._authTokenProvider.getCurrentAccessToken()).pipe(
      switchMap((token) => {
        const headers = this._requestHeaderProvider.getXApiHeaders(
          DataRequestLevel.tenant,
          token
        );
        return this._http.post<any>(
          `${
            environment.api_url.platform_service
          }${this._apiRequestHandler.getDataLevelUrlPart(
            DataRequestLevel.tenant
          )}/users/access-control/security-roles/username/${userId}`,
          roles,
          { headers }
        );
      })
    );
  }


  //* DELETE methods
  public deleteUserByAdmin(
    userId: string,
    level: DataRequestLevel,
    organizationCode?: string
  ): Observable<HttpResponse<any>> {
    return from(this._authTokenProvider.getCurrentAccessToken()).pipe(
      switchMap((token) => {
        const headers = this._requestHeaderProvider.getXApiHeaders(
          DataRequestLevel.tenant,
          token
        );

        const url =
          level === DataRequestLevel.tenant
            ? `${
                environment.api_url.platform_service
              }${this._apiRequestHandler.getDataLevelUrlPart(
                DataRequestLevel.tenant
              )}/users/username/${userId}`
            : `${
                environment.api_url.platform_service
              }${this._apiRequestHandler.getDataLevelUrlPart(
                DataRequestLevel.tenant
              )}/users/organizations/organizationCode/${organizationCode}/username/${userId}`;

        return this._http.delete<any>(url, { headers });
      })
    );
  }

  //PATCH methods
  public updateUser(user: IUserDetails): Observable<HttpResponse<any>> {
    return from(this._authTokenProvider.getCurrentAccessToken()).pipe(
      switchMap((token) => {
        const headers = this._requestHeaderProvider.getXApiHeaders(
          DataRequestLevel.tenant,
          token
        );
        return this._http.patch<any>(
          environment.api_url.platform_service + '/myself-user-mgmt/',
          user,
          { headers }
        );
      })
    );
  }
}
