import { Injectable } from '@angular/core';
import { IUserDetails } from '../interfaces/i-user-details';
import { Observable, firstValueFrom, forkJoin, map, of } from 'rxjs';
import { UserRepositoryService } from '../repositories/user-repository.service';
import { Router } from '@angular/router';
import { GlobalLoaderTriggerService } from './global-loader-trigger.service';
import { IAccessControlDetails } from '../interfaces/i-access-control-details';
import { ModalController } from '@ionic/angular';
import { LegalLockComponent } from '../shared/components/legal-lock/legal-lock.component';
import { AdministrationPermissionsPlatform } from '../types/administration-permissions-platform';
import { AdministrationPermissionsNews } from '../types/administration-permissions-news';
import { PermissionService } from './permission.service';
import { DataRequestLevel } from '../types/data-request-level';
import { AmplifyConfiguratorService } from './amplify-configurator.service';

@Injectable({
  providedIn: 'root',
})
export class UserAccountService {
  constructor(
    private _userRepository: UserRepositoryService,
    private _router: Router,
    private _globalLoader: GlobalLoaderTriggerService,
    private _modalController: ModalController,
    private _permissionService: PermissionService,
    private _amplifyConfigurator: AmplifyConfiguratorService
  ) {}

  private userDetails?: IUserDetails;
  private userAccessLevel?: IAccessControlDetails;
  private userPermissions?: (
    | AdministrationPermissionsNews
    | AdministrationPermissionsPlatform
  )[];
  private userDataLevel: DataRequestLevel = DataRequestLevel.none;
  private isAuthorized = false;

  private isLegacyLock = false;

  public get IsAuthorized(): boolean {
    return this.isAuthorized;
  }

  public get UserDetails(): IUserDetails | undefined {
    return this.userDetails;
  }

  public get UserPermissions():
    | (AdministrationPermissionsNews | AdministrationPermissionsPlatform)[]
    | undefined {
    return this.userPermissions;
  }

  public get UserDataLevel(): DataRequestLevel {
    return this.userDataLevel;
  }

  public get UserAccessLevel(): IAccessControlDetails | undefined {
    return this.userAccessLevel;
  }

  public async checkUserRoleWithAsyncAwait(): Promise<boolean> {

    if(!this._amplifyConfigurator.configured) {
      await this._amplifyConfigurator.loadInitialAmplifyConfig();
    }

    let roleSufficient = true;
    if (!this.UserDetails) {
      this._globalLoader.showLoader();
      try {
        await firstValueFrom(this.setUserDetails());
        this._globalLoader.hideLoader();
      } catch (error) {
        this._globalLoader.hideLoader();
        let tenant = localStorage.getItem('tenant');
        if (tenant) {
          this._router.navigate([`admin-login/${tenant}`]);
        } else {
          this._router.navigate(['no-tenant-fallback']);
        }
      }
    }

    return roleSufficient;
  }

  public setUserDetails(): Observable<boolean> {
    this.isAuthorized = false;

    const whoAmIRequests: Observable<any>[] = [];

    whoAmIRequests.push(this._userRepository.whoAmI());
    whoAmIRequests.push(this._userRepository.whatsMyAccess());

    try {
      return forkJoin(whoAmIRequests).pipe(
        map((responses) => {
          const userResp = responses[0] as IUserDetails;
          const access = responses[1] as IAccessControlDetails;

          if (userResp) {
            this.userDetails = userResp;
            this.userAccessLevel = access;
            this.userPermissions =
              this._permissionService.getPermissionsForRoles(
                this.userAccessLevel
              );
            this.userDataLevel = this.setLevel(this.userAccessLevel);

            this.isAuthorized = true;

            if (!this.userDetails.termsOfUseAccepted && !this.isLegacyLock) {
              this.isLegacyLock = true;
              this._modalController
                .create({
                  component: LegalLockComponent,
                  backdropDismiss: false,
                  componentProps: {
                    myself: this.UserDetails,
                  },
                })
                .then((modal) => {
                  modal.present().then(() => {
                    modal.onDidDismiss().then((result) => {
                      this.isLegacyLock = false;
                    });
                  });
                });
            }

            return true;
          } else {
            this.isAuthorized = false;
            this.userDetails = undefined;
            return true;
          }
        })
      );
    } catch (error) {
      console.error(error);
      return of(false);
    }
  }

  public resetUserData(): void {
    this.isAuthorized = false;
    this.userDetails = undefined;
  }

  private setLevel(userAccess: IAccessControlDetails): DataRequestLevel {
    if (
      userAccess.tenantRoles.some((tR) => {
        return tR.includes('admin');
      })
    ) {
      return DataRequestLevel.tenant;
    } else if (
      userAccess.organizations.some((o) => {
        return o.organizationRoles.some((oR) => {
          return oR.includes('admin');
        });
      })
    ) {
      return DataRequestLevel.organization;
    } else {
      return DataRequestLevel.none;
    }
  }
}
