import { Component, Input } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  @Input() title?: string;
  @Input() message?: string;

  constructor(
    private _modalController: ModalController,
    public platform: Platform
  ) {}

  confirm(response: boolean) {
    this._modalController.dismiss(response ? 'confirm' : 'cancel');
  }
}
