<ion-header>
  <ion-toolbar>
    <ion-title> {{ title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismissModal()" data-cy="modal_close">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
