import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AdminDataLevelService } from 'src/app/global-services/admin-data-level.service';
import { DataRequestLevel } from 'src/app/types/data-request-level';

@Component({
  selector: 'app-admin-data-level-switcher',
  templateUrl: './admin-data-level-switcher.component.html',
  styleUrls: ['./admin-data-level-switcher.component.scss'],
})
export class AdminDataLevelSwitcherComponent {
  constructor(
    public adminDataLevelService: AdminDataLevelService,
    private _translate: TranslateService
  ) {}

  public getLevelKey(level: DataRequestLevel): string {
    if (
      level.includes('organization') &&
      (this.adminDataLevelService.availableWorkplaces?.length || 0) <= 1
    ) {
      return (
        this.adminDataLevelService.selectedWorkplace?.friendlyName ||
        this._translate.instant('data_levels.single_organization')
      );
    } else {
      return this._translate.instant(`data_levels.${DataRequestLevel[level]}`);
    }
  }
}
