import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { IRegion } from '../interfaces/i-region';

@Injectable({
  providedIn: 'root'
})
export class RegionSelectionService {

  constructor(private _storageService: StorageService) { }

  private selectedRegion?: IRegion;

  public selectRegion(regionGuid: string): Promise<void> {
    return this._storageService.setStorage('selected_region', regionGuid);
  }

  public getSelectedRegion(): Promise<any> {
    return this._storageService.getStorageValue('selected_region');
  }

  public SetSelectedRegion(region: IRegion) {
    this.selectedRegion = region;
  }

  public get SelectedRegion(): IRegion | undefined {
    return this.selectedRegion;
  }
}
