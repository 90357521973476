<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button
        data-cy="header_back_button"
        [text]="'buttons.back' | translate"
        defaultHref="admin"
      ></ion-back-button>
    </ion-buttons>
    <ion-title>{{ title }}</ion-title>
  </ion-toolbar>
</ion-header>
