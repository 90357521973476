import { Injectable } from '@angular/core';
import { PlatformInformationService } from 'src/app/global-services/platform-information.service';

@Injectable({
  providedIn: 'root',
})
export class MobileEditorToggleService {
  constructor(private _platformInformation: PlatformInformationService) {}

  private isMobileEditor = false;

  public switchEditorToMobile(): void {
    this.isMobileEditor = !this.isMobileEditor;
  }

  public get IsMobileEditor(): boolean {
    return this.isMobileEditor;
  }

  public get IsMobile(): boolean {
    return this._platformInformation.IsMobilePlatform;
  }
}
