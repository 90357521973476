import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { AdministrationLevels } from '../types/administration-levels';
import { DataRequestLevel } from '../types/data-request-level';
import { IWorkplace } from '../interfaces/i-workplace';
import { IRegion } from '../interfaces/i-region';
import { GlobalLoaderTriggerService } from './global-loader-trigger.service';
import { AlertService } from './alert.service';
import { WorkplaceRepositoryService } from '../repositories/workplace-repository.service';
import { UserAccountService } from './user-account.service';
import { IAccessControlDetails } from '../interfaces/i-access-control-details';

@Injectable({
  providedIn: 'root',
})
export class AdminDataLevelService {
  constructor(
    private _globalLoaderTrigger: GlobalLoaderTriggerService,
    private _alertService: AlertService,
    private _workplacesRepository: WorkplaceRepositoryService,
    private _userService: UserAccountService
  ) {
    this.defaultLevel =
      this._userService.UserDataLevel || DataRequestLevel.none;
    this.currentLevel = this.defaultLevel;
    this.onLevelChange({
      detail: {
        value: this.currentLevel,
      },
    });
  }

  private levelChanged? = new BehaviorSubject<DataRequestLevel>(
    this._userService.UserDataLevel
  );

  public levelChanged$?: Observable<DataRequestLevel> =
    this.levelChanged?.asObservable();

  private workplaceChanged? = new BehaviorSubject<string>('');

  public workplaceChanged$?: Observable<string> =
    this.workplaceChanged?.asObservable();

  private defaultLevel: DataRequestLevel =
    this._userService.UserDataLevel || DataRequestLevel.none;
  private currentLevel: DataRequestLevel = this.defaultLevel;

  public selectedRegion?: IRegion;
  public selectedWorkplace?: IWorkplace;
  public selectedWorkplaceCode?: string;

  public availableRegions?: IRegion[];
  public availableWorkplaces?: IWorkplace[];

  public get CurrentLevel(): DataRequestLevel {
    return this.currentLevel;
  }

  public AvailableLevels(): DataRequestLevel[] {
    return this._userService.UserAccessLevel
      ? this.filteredAvailableLevels(this._userService.UserAccessLevel)
      : [];
  }

  public get DefaultLevel(): DataRequestLevel {
    return this.defaultLevel;
  }

  public setDefaultLevel(): void {
    this.currentLevel = this.defaultLevel;
  }

  public setSelectedLevel(level: DataRequestLevel): void {
    this.currentLevel = level;
  }

  public async onLevelChange(event: any, silent?: boolean): Promise<void> {
    if (!this.availableWorkplaces) {
      const workplaces: IWorkplace[] = await firstValueFrom(
        this._workplacesRepository.getPublicWorkplacesForTenant(100, 0, {})
      );
      const filteredByPermission: IWorkplace[] =
        this.filterWorkplacesByUserPermissions(
          workplaces,
          this._userService.UserAccessLevel?.organizations || []
        );

      this.availableWorkplaces = filteredByPermission || [];

      if (!this.selectedWorkplaceCode && this.availableWorkplaces.length > 0) {
        this.selectedWorkplace = filteredByPermission.find((w) => {
          return w.status === 'RUNNING';
        });

        this.selectedWorkplaceCode =
          this.selectedWorkplace?.organizationCode || '';
      }
    }

    this.setSelectedLevel(event?.detail?.value);
    if (this.currentLevel === DataRequestLevel.organization) {
      this.availableWorkplaces = [];
      this._globalLoaderTrigger.showLoader();
      try {
        const workplaces: IWorkplace[] = await firstValueFrom(
          this._workplacesRepository.getPublicWorkplacesForTenant(100, 0, {})
        );

        this._globalLoaderTrigger.hideLoader();

        const filteredByPermission: IWorkplace[] =
          this.filterWorkplacesByUserPermissions(
            workplaces,
            this._userService.UserAccessLevel?.organizations || []
          );

        this.availableWorkplaces = filteredByPermission || [];

        if (
          !this.selectedWorkplaceCode &&
          this.availableWorkplaces.length > 0
        ) {
          this.selectedWorkplace = filteredByPermission.find((w) => {
            return w.status === 'RUNNING';
          });

          this.selectedWorkplaceCode =
            this.selectedWorkplace?.organizationCode || '';
        }
      } catch (error) {
        console.error(error);
        this._globalLoaderTrigger.hideLoader();
        this._alertService.presentAlert(
          'Komunikat',
          '',
          'Nie udało się wyświetlić organizacji',
          [{ text: 'OK' }],
          () => {}
        );
      }
    }

    if (!silent) {
      this.levelChanged?.next(event?.detail?.value);
    }
  }

  public onWorkplaceChange(code: string | undefined): void {
    this.selectedWorkplaceCode = code;
    this.selectedWorkplace = this.availableWorkplaces?.find((aW) => {
      return aW.organizationCode === code;
    });
    this.workplaceChanged?.next(code || '');
  }

  private filteredAvailableLevels(
    userLevel: IAccessControlDetails
  ): DataRequestLevel[] {
    const levels: DataRequestLevel[] = [];

    const roles: AdministrationLevels[] = [];
    roles.push(...userLevel.tenantRoles);

    userLevel.organizations.forEach((o) => {
      o.organizationRoles.forEach((oR) => {
        if (!roles.includes(oR)) {
          roles.push(oR);
        }
      });
    });

    roles.forEach((r) => {
      switch (r) {
        case AdministrationLevels.TenantPlatformAdmin:
        case AdministrationLevels.TenantNewsAdmin:
          if (!levels.includes(DataRequestLevel.tenant)) {
            levels.push(DataRequestLevel.tenant);
          }
          break;
        case AdministrationLevels.OrganizationNewsAdmin:
        case AdministrationLevels.OrganizationPlatformAdmin:
        case AdministrationLevels.SupportPlatformAdmin:
          if (
            !levels.includes(DataRequestLevel.organization) &&
            userLevel.organizations.length > 0
          ) {
            levels.push(DataRequestLevel.organization);
          }

          break;
      }
    });

    return levels;
  }

  public levelRequestAvailabilityCheck(level: DataRequestLevel): boolean {
    return (
      level !== DataRequestLevel.organization ||
      (level === DataRequestLevel.organization &&
        this.selectedWorkplaceCode !== undefined)
    );
  }

  private filterWorkplacesByUserPermissions(
    workplaces: IWorkplace[],
    userAssignedWorkplaces: {
      organizationCode: string;
      organizationRoles: AdministrationLevels[];
    }[]
  ): IWorkplace[] {
    return workplaces.filter((workplace) =>
      userAssignedWorkplaces.some(
        (assigned) => assigned.organizationCode === workplace.organizationCode
      )
    );
  }
}
