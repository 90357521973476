<app-modal-header [title]="title"></app-modal-header>
<ion-content class="ion-padding">
  <p>{{ message }}</p>
  <br />
  <ion-button color="success" (click)="confirm(true)"
    ><ion-icon slot="start" name="checkmark-outline"></ion-icon>Tak</ion-button
  >
  <ion-button color="danger" (click)="confirm(false)"
    ><ion-icon slot="start" name="close-outline"></ion-icon>Nie</ion-button
  >
</ion-content>
