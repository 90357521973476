import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AlertService } from '../global-services/alert.service';
import { AuthenticationService } from '../global-services/authentication.service';
import { GlobalLoaderTriggerService } from '../global-services/global-loader-trigger.service';
import { UserAccountService } from '../global-services/user-account.service';
import { IUserDetails } from '../interfaces/i-user-details';
import { UserRepositoryService } from '../repositories/user-repository.service';
import { ActiveTenantService } from '../global-services/active-tenant.service';
import { TenantRepositoryService } from '../global-services/tenant-repository.service';
import { ICognitoConfig } from '../interfaces/i-cognito-config';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.page.html',
  styleUrls: ['./admin-login.page.scss'],
})
export class AdminLoginPage implements OnInit {
  constructor(
    private _userAccountService: UserAccountService,
    private _authenticationService: AuthenticationService,
    private _globalLoaderTrigger: GlobalLoaderTriggerService,
    private _alertService: AlertService,
    private _userRepository: UserRepositoryService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    public activeTenant: ActiveTenantService,
    private _tenantRepository: TenantRepositoryService
  ) {}

  public tenant: string | null = null;

  public newDisplayName?: string;
  public isInEdit = false;

  private isAuthenticated = false;

  public get IsAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  public get UserDetails(): IUserDetails | undefined {
    return this._userAccountService.UserDetails;
  }

  async ngOnInit(): Promise<void> {
    this.tenant = this._activatedRoute.snapshot.paramMap.get('tenantCode');

    if (this.tenant) {
      this.saveTenantCode(this.tenant);
      const tenant: ICognitoConfig = await firstValueFrom(
        this._tenantRepository.getTenantInfo(this.tenant)
      );
      this.activeTenant.activeTenant = tenant;
    } else {
      console.error('no tenant');
    }
  }

  async ionViewDidEnter() {
    this._globalLoaderTrigger.showLoader();
    try {
      this.isAuthenticated = await firstValueFrom(
        this._userAccountService.setUserDetails()
      );
    } catch (error) {
      console.error(error);
    }

    this._globalLoaderTrigger.hideLoader();
  }

  public logout(): void {
    this.isAuthenticated = false;
    this._authenticationService.logoutWithAmplify();
  }

  public login(): void {
    this._authenticationService.loginWithAmplify();
  }

  public openPanel(): void {
    this._router.navigateByUrl('admin');
  }

  public switchEdit(): void {
    this.newDisplayName = this.UserDetails?.displayName;
    this.isInEdit = !this.isInEdit;
  }

  public update(): void {
    if (this.UserDetails) {
      const userToUpdate: IUserDetails = JSON.parse(
        JSON.stringify(this.UserDetails)
      );
      if (this.newDisplayName) {
        userToUpdate.displayName = this.newDisplayName;
        this._globalLoaderTrigger.showLoader();
        this._userRepository.updateUser(userToUpdate).subscribe({
          next: async () => {
            this.isInEdit = false;
            await firstValueFrom(this._userAccountService.setUserDetails());
            this.newDisplayName = this.UserDetails?.displayName;
            this._globalLoaderTrigger.hideLoader();
          },
          error: (error) => {
            console.error(error);
            this._globalLoaderTrigger.hideLoader();
            this._alertService.presentAlert(
              'Komunikat',
              '',
              'Nie udało się zaktualizować danych użytkownika',
              [{ text: 'OK' }],
              () => {}
            );
          },
        });
      }
    }
  }

  private saveTenantCode(code: string): void {
    localStorage.setItem('tenant', code);
  }

  private loadTenantCode(): string | null {
    return localStorage.getItem('tenant');
  }
}
