<div class="organizationsBadgesContainer">
  <ion-chip
    *ngFor="let organization of favoriteOrganizations"
    class="chipCut"
    [routerLink]="'/single-workplace/' + organization.id"
  >
    <ion-icon name="bookmark"> </ion-icon>&nbsp;
    {{ organization.name }}
  </ion-chip>
</div>
