import { Pipe, PipeTransform } from '@angular/core';
import { AdministrationLevels } from '../types/administration-levels';

@Pipe({
  name: 'roleFriendlyName',
})
export class RoleFriendlyNamePipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case AdministrationLevels.TenantPlatformAdmin:
        return 'Administrator Platformy - Region';
      case AdministrationLevels.TenantNewsAdmin:
        return 'Administrator Wiadomości - Region';
      case AdministrationLevels.TenantNewsMember:
        return 'Członek Regionu (poziom Wiadomości)';
      case AdministrationLevels.TenantPlatformMember:
        return 'Członek Regionu (poziom Platformy)';
      case AdministrationLevels.OrganizationNewsAdmin:
        return 'Administrator Wiadomości - Organizacja';
      case AdministrationLevels.OrganizationPlatformAdmin:
        return 'Administrator Platfromy - Organizacja';
      case AdministrationLevels.SupportPlatformAdmin:
        return 'Platform Support Admin';
      default:
        return 'Nieznana Rola';
    }
  }

}
