export enum AdministrationPermissionsNews {
  FileUpload = 'news.file.upload',
  OrganizationMetricRead = 'news.organization.metric.read',
  OrganizationArticleManagement = 'news.organization.article.management',
  OrganizationMultimediaManagement = 'news.organization.multimedia.management',
  OrganizationNotificationManagement = 'news.organization.notification.management',
  TenantMetricRead = 'news.tenant.metric.read',
  TenantArticleManagement = 'news.tenant.article.management',
  TenantMultimediaManagement = 'news.tenant.multimedia.management',
  TenantMagazineManagement = 'news.tenant.magazine.management',
  TenantNotificationManagement = 'news.tenant.notification.management',
  BaseAccess = 'news.base.access',
}
