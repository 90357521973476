import { Component, Input } from '@angular/core';
import { SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { S3FileContentType } from 'src/app/types/s3-file-content-type';

@Component({
  selector: 'app-file-preview-modal',
  templateUrl: './file-preview-modal.component.html',
  styleUrls: ['./file-preview-modal.component.scss'],
})
export class FilePreviewModalComponent {
  @Input() fileUrl?: SafeUrl | string | SafeResourceUrl;
  @Input() fileType?: S3FileContentType;
  @Input() fileName?: string;

  constructor(private _modalController: ModalController) {}

  private showLoader = true;

  public get ShowLoader(): boolean {
    return this.showLoader;
  }

  dismissModal() {
    this._modalController.dismiss();
  }

  onPdfLoad() {
    this.showLoader = false;
  }
}
