// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: '1.1.14-dev',
  mobileAppVersions: {
    android: '1.1.14',
    ios: '1.1.14',
  },
  instance: 'develop',
  appName: 'DEV GGSP Admin',
  appSubtitle: '',
  supportEmail: 'wsparcie@ggsp.pl',
  legal: '© 2024',
  firebase: {
    tenant: '',
    topic_all: 'all',
  },
  x_api_headers: {
    api_key: 'api.key.base.access.nszz',
  },
  api_url: {
    news_service: 'https://organization-news-service.backend.ggspdev.pl/api',
    news_service_public:
      'https://organization-news-service.backend.ggspdev.pl/api/public',
    platform_service: 'https://platform-service.backend.ggspdev.pl/api',
    platform_service_public:
      'https://platform-service.backend.ggspdev.pl/api/public',
  },
  cognito: {
    url: 'https://nszz-login-dev.auth.eu-west-1.amazoncognito.com/oauth2/authorize',
    oauth_domain: 'https://nszz-login-dev.auth.eu-west-1.amazoncognito.com',
    oauth_scopes: ['openid profile email'],
    oauth_redirect_sign_in: ['http://localhost:8100/login'],
    oauth_redirect_sign_out: ['http://localhost:8100/logout'],
    oauth_response_type: 'code' as 'code' | 'token',
  },
  apiUrl:
    'https://3gjy5c2dl2.execute-api.eu-west-1.amazonaws.com/development/api/v1',
    fileSettings: {
      allowedGraphicsFormats: '.jpeg, .jpg, .png, .mp4',
      allowedFormats: '.jpeg, .jpg, .png, .pdf, .mp4',
      maxFiles: {
        attachments: 5,
        gallery: 30,
        magazines: 10,
      },
      compression: {
        newsImageMaxMB: 0.5,
        newspaperImageMaxMB: 0.5,
        benefitImageMaxMB: 0.5,
        galleryTitleImageMaxMB: 0.5,
        galleryImageMaxMB: 1,
        multimediaImageMaxMB: 2,
        workplaceImageMaxMB: 0.5,
      },
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
