import { Component } from '@angular/core';
import { DataLevelService } from 'src/app/global-services/data-level.service';
import { DataRequestLevel } from 'src/app/types/data-request-level';

@Component({
  selector: 'app-data-level-switcher',
  templateUrl: './data-level-switcher.component.html',
  styleUrls: ['./data-level-switcher.component.scss'],
})
export class DataLevelSwitcherComponent {
  constructor(public dataLevelService: DataLevelService) {}

  public getLevelKey(level: DataRequestLevel): string {
    return DataRequestLevel[level];
  }
}
