<div *ngIf="trigger">
  <ion-list>
    <ion-item detail="false">
      <ion-thumbnail>
        <ion-skeleton-text
          [animated]="true"
          style="border-radius: 50%"
        ></ion-skeleton-text>
      </ion-thumbnail>
      <ion-label style="margin-left: 1rem">
        <ion-skeleton-text
          animated="true"
          style="width: 5rem; height: 1rem"
        ></ion-skeleton-text>
        <ion-skeleton-text
          animated="true"
          style="width: 10rem; height: 1rem; margin-top: 0.5rem"
        ></ion-skeleton-text> </ion-label></ion-item
  ></ion-list>
  <ion-list>
    <ion-item detail="false">
      <ion-thumbnail>
        <ion-skeleton-text
          [animated]="true"
          style="border-radius: 50%"
        ></ion-skeleton-text>
      </ion-thumbnail>
      <ion-label style="margin-left: 1rem">
        <ion-skeleton-text
          animated="true"
          style="width: 5rem; height: 1rem"
        ></ion-skeleton-text>
        <ion-skeleton-text
          animated="true"
          style="width: 10rem; height: 1rem; margin-top: 0.5rem"
        ></ion-skeleton-text> </ion-label></ion-item
  ></ion-list>
</div>
