import { Injectable } from '@angular/core';
import { AdministrationLevels } from '../types/administration-levels';
import { AdministrationPermissionsNews } from '../types/administration-permissions-news';
import { AdministrationPermissionsPlatform } from '../types/administration-permissions-platform';
import { IAccessControlDetails } from '../interfaces/i-access-control-details';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private rolePermissionsMap: {
    role: AdministrationLevels;
    permissions: (
      | AdministrationPermissionsNews
      | AdministrationPermissionsPlatform
    )[];
  }[] = [
    {
      role: AdministrationLevels.OrganizationNewsAdmin,
      permissions: [
        AdministrationPermissionsNews.FileUpload,
        AdministrationPermissionsNews.OrganizationMetricRead,
        AdministrationPermissionsNews.OrganizationArticleManagement,
        AdministrationPermissionsNews.OrganizationMultimediaManagement,
        AdministrationPermissionsNews.OrganizationNotificationManagement,
      ],
    },
    {
      role: AdministrationLevels.TenantNewsAdmin,
      permissions: [
        AdministrationPermissionsNews.FileUpload,
        AdministrationPermissionsNews.TenantMetricRead,
        AdministrationPermissionsNews.TenantArticleManagement,
        AdministrationPermissionsNews.TenantMultimediaManagement,
        AdministrationPermissionsNews.TenantNotificationManagement,
        AdministrationPermissionsNews.TenantMagazineManagement
      ],
    },
    {
      role: AdministrationLevels.TenantNewsMember,
      permissions: [AdministrationPermissionsNews.BaseAccess],
    },
    {
      role: AdministrationLevels.OrganizationPlatformAdmin,
      permissions: [
        AdministrationPermissionsPlatform.FileUpload,
        AdministrationPermissionsPlatform.OrganizationMetadataManagement,
      ],
    },
    {
      role: AdministrationLevels.SupportPlatformAdmin,
      permissions: [AdministrationPermissionsPlatform.Management],
    },
    {
      role: AdministrationLevels.TenantPlatformAdmin,
      permissions: [
        AdministrationPermissionsPlatform.FileUpload,
        AdministrationPermissionsPlatform.TenantUserManagement,
        AdministrationPermissionsPlatform.TenantMetadataManagement,
        AdministrationPermissionsPlatform.TenantOrganizationManagement,
      ],
    },
    {
      role: AdministrationLevels.TenantPlatformMember,
      permissions: [AdministrationPermissionsPlatform.BaseAccess],
    },
  ];

  private getPermissionsForRole(
    role: AdministrationLevels
  ): (AdministrationPermissionsNews | AdministrationPermissionsPlatform)[] {
    return (
      this.rolePermissionsMap.find((rPM) => {
        return rPM.role === role;
      })?.permissions || []
    );
  }

  public getPermissionsForRoles(
    userRoles: IAccessControlDetails
  ): (AdministrationPermissionsNews | AdministrationPermissionsPlatform)[] {
    const permissions: (
      | AdministrationPermissionsNews
      | AdministrationPermissionsPlatform
    )[] = [];

    const roles: AdministrationLevels[] = [];

    roles.push(...userRoles.tenantRoles);

    userRoles.organizations.forEach((o) => {
      o.organizationRoles.forEach((oR) => {
        if (!roles.includes(oR)) {
          roles.push(oR);
        }
      });
    });

    roles.forEach((r) => {
      const permissionsForRole: (
        | AdministrationPermissionsNews
        | AdministrationPermissionsPlatform
      )[] = this.getPermissionsForRole(r);
      permissionsForRole.forEach((p) => {
        if (!permissions.includes(p)) {
          permissions.push(p);
        }
      });
    });

    return permissions;
  }
}
