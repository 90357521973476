import { Injectable } from '@angular/core';
import { fetchAuthSession } from 'aws-amplify/auth';
import { StorageService } from './storage.service';
import { ICognitoAuthToken } from '../interfaces/i-cognito-auth-token';

@Injectable({
  providedIn: 'root',
})
export class AuthTokenProviderService {
  constructor(private _storage: StorageService) {}

  currentToken: ICognitoAuthToken | null = null;

  public async setCurrentAuthTokens(): Promise<void> {
    const tokens = (await fetchAuthSession({ forceRefresh: true })).tokens;
    if (tokens) {
      this.currentToken = tokens;
    }
  }

  public async getCurrentAccessToken(): Promise<ICognitoAuthToken | null> {
    if (!this.currentToken) {
      await this.setCurrentAuthTokens();
    }

    return new Promise((resolve, reject) => {
      if (this.currentToken) {
        resolve(this.currentToken);
      } else {
        reject('no token');
      }
    });
  }

  public clearTokens(): void {
    this.currentToken = null;
  }
}
