import { Component, Input } from '@angular/core';
import { NotificationsService } from 'src/app/global-services/notifications.service';
import {
  trigger,
  state,
  style,
  transition,
  animate,
  keyframes,
} from '@angular/animations';
import { PlatformInformationService } from 'src/app/global-services/platform-information.service';
import { RegionSelectionService } from 'src/app/global-services/region-selection.service';
import { VersionService } from 'src/app/global-services/version.service';

@Component({
  selector: 'app-universal-header',
  templateUrl: './universal-header.component.html',
  styleUrls: ['./universal-header.component.scss'],
  animations: [
    trigger('wiggle', [
      state(
        'start',
        style({
          opacity: '1',
        })
      ),
      state(
        'end',
        style({
          opacity: '1',
        })
      ),
      transition('start <=> end', [
        animate(
          '3s',
          keyframes([
            style({ transform: 'rotate(0deg) scale(1)' }),
            style({ transform: 'rotate(10deg) scale(1.05)' }),
            style({ transform: 'rotate(-10deg) scale(1.05)' }),
            style({ transform: 'rotate(10deg) scale(1)' }),
            style({ transform: 'rotate(-10deg) scale(1)' }),
            style({ transform: 'rotate(10deg) scale(1.05)' }),
            style({ transform: 'rotate(-10deg) scale(1.05)' }),
            style({ transform: 'rotate(10deg) scale(1)' }),
            style({ transform: 'rotate(-10deg) scale(1)' }),
            style({ transform: 'rotate(10deg) scale(1.05)' }),
            style({ transform: 'rotate(-10deg) scale(1.05)' }),
            style({ transform: 'rotate(10deg) scale(1)' }),
            style({ transform: 'rotate(-10deg) scale(1)' }),
            style({ transform: 'rotate(10deg) scale(1.05)' }),
            style({ transform: 'rotate(-10deg) scale(1.05)' }),
            style({ transform: 'rotate(0deg) scale(1)' }),
          ])
        ),
      ]),
    ]),
  ],
})
export class UniversalHeaderComponent {
  @Input() title?: string;

  constructor(
    public regionSelectionService: RegionSelectionService,
    public notificationsService: NotificationsService,
    public platformService: PlatformInformationService,
    public versionService: VersionService
  ) {}
}
