import { Injectable } from '@angular/core';
import { RequestHeaderProviderService } from '../global-services/request-header-provider.service';
import { AuthTokenProviderService } from '../global-services/auth-token-provider.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { IMetadata } from '../interfaces/i-metadata';
import { from, Observable, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IOrganizationDetails } from '../interfaces/i-organization-details';
import { DataRequestLevel } from '../types/data-request-level';
import { IContacts } from '../interfaces/i-contacts';
import { ILinks } from '../interfaces/i-links';
import { ApiRequestUrlHandlerService } from '../global-services/api-request-url-handler.service';
import { ITerms } from '../interfaces/i-terms';
import { IPolicy } from '../interfaces/i-policy';
import { IExternalFormData } from '../interfaces/i-external-form-data';

@Injectable({
  providedIn: 'root',
})
export class MetadataRepositoryService {
  constructor(
    private _http: HttpClient,
    private _authTokenProvider: AuthTokenProviderService,
    private _requestHeaderProvider: RequestHeaderProviderService,
    private _apiRequestHandler: ApiRequestUrlHandlerService
  ) {}

  public getMetadata(
    key: string,
    dataLevel: DataRequestLevel,
    organizationCode?: string
  ): Observable<IMetadata> {
    const headers = this._requestHeaderProvider.getXApiHeaders(
      DataRequestLevel.tenant
    );

    const url =
      dataLevel === DataRequestLevel.tenant
        ? `${
            environment.api_url.platform_service_public
          }${this._apiRequestHandler.getDataLevelUrlPart(
            dataLevel
          )}/metadata/key/${key}`
        : `${
            environment.api_url.platform_service_public
          }${this._apiRequestHandler.getDataLevelUrlPart(
            dataLevel
          )}/organization-code/${organizationCode}/metadata/key/${key}`;
    return this._http.get<IMetadata>(url, { headers });
  }

  public createMetadata(
    key: string,
    body: any,
    dataLevel: DataRequestLevel,
    organizationCode?: string
  ): Observable<HttpResponse<any>> {
    return from(this._authTokenProvider.getCurrentAccessToken()).pipe(
      switchMap((token) => {
        const headers = this._requestHeaderProvider.getXApiHeaders(
          DataRequestLevel.tenant,
          token
        );
        const url =
          dataLevel === DataRequestLevel.tenant
            ? `${
                environment.api_url.platform_service
              }${this._apiRequestHandler.getDataLevelUrlPart(
                dataLevel
              )}/mgmt/metadata/key/${key}`
            : `${
                environment.api_url.platform_service
              }${this._apiRequestHandler.getDataLevelUrlPart(
                dataLevel
              )}/mgmt/organization-code/${organizationCode}/metadata/key/${key}`;
        return this._http.post<HttpResponse<any>>(url, body, { headers });
      })
    );
  }

  public updateMetadata(
    key: string,
    body:
      | IOrganizationDetails
      | IContacts
      | ILinks
      | ITerms
      | IPolicy
      | IExternalFormData,
    dataLevel: DataRequestLevel,
    organizationCode?: string
  ): Observable<HttpResponse<any>> {
    return from(this._authTokenProvider.getCurrentAccessToken()).pipe(
      switchMap((token) => {
        const headers = this._requestHeaderProvider.getXApiHeaders(
          DataRequestLevel.tenant,
          token
        );
        const url =
          dataLevel === DataRequestLevel.tenant
            ? `${
                environment.api_url.platform_service
              }${this._apiRequestHandler.getDataLevelUrlPart(
                dataLevel
              )}/mgmt/metadata/key/${key}`
            : `${
                environment.api_url.platform_service
              }${this._apiRequestHandler.getDataLevelUrlPart(
                dataLevel
              )}/mgmt/organization-code/${organizationCode}/metadata/key/${key}`;
        return this._http.put<HttpResponse<any>>(url, body, { headers });
      })
    );
  }
}
