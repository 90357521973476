<app-modal-header [title]="'Powiadomienia'"></app-modal-header>
<ion-content [fullscreen]="true">
  <app-notifications-skeleton
    [trigger]="notificationsService.isLoading"
  ></app-notifications-skeleton>

  <div *ngIf="!notificationsService.isLoading">
    <ion-list *ngFor="let notification of notificationsService.notifications">
      <ion-item
        detail="false"
        button="true"
        (click)="notificationsService.presentModal(notification)"
      >
        <ion-icon
          [name]="notification.read ? 'notifications-outline' : 'notifications'"
          color="primary"
          slot="start"
        ></ion-icon>
        <ion-label>
          <strong> {{ notification.title }}</strong>
          <br />
          <ion-note color="medium" class="ion-text-wrap">
            {{ notification.message }}
          </ion-note>
          <br />
          <p>
            {{ notification.createdAt | date : "dd/MM/YYYY HH:mm" }}
          </p>
        </ion-label>
      </ion-item>
    </ion-list>

    <app-data-placeholder
      *ngIf="(notificationsService.notifications?.length || 0) <= 0"
      [icon]="'notifications-circle-outline'"
      [placeholderText]="'Nie ma powiadomień'"
    ></app-data-placeholder>
  </div>
</ion-content>
