import { Component } from '@angular/core';
import { MobileEditorToggleService } from './mobile-editor-toggle.service';

@Component({
  selector: 'app-mobile-editor-toggle',
  templateUrl: './mobile-editor-toggle.component.html',
  styleUrls: ['./mobile-editor-toggle.component.scss'],
})
export class MobileEditorToggleComponent {
  constructor(public mobileEditorToggle: MobileEditorToggleService) {}
}
