<app-modal-header [title]="'Powiadomienie'"></app-modal-header>
<ion-content [fullscreen]="true" class="ion-padding">
  <h1>{{ notification?.title }}</h1>

  <div>
    {{ notification?.message }}
  </div>

  <h4>{{ notification?.createdAt | date : "dd/MM/YYYY HH:mm" }}</h4>
</ion-content>
