import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestHeaderProviderService } from '../global-services/request-header-provider.service';
import { INotificationQuery } from '../interfaces/i-notification-query';
import { IPushNotification } from '../interfaces/i-push-notification';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { DataRequestLevel } from '../types/data-request-level';

@Injectable({
  providedIn: 'root',
})
export class NotificationsRepositoryService {
  constructor(
    private _http: HttpClient,
    private _requestHeaderProvider: RequestHeaderProviderService
  ) {}

  public getLatestNotifications(
    notificationQuery: INotificationQuery,
    pageSize: number,
    pageNumber: number
  ): Observable<IPushNotification[]> {
    const headers = this._requestHeaderProvider.getXApiHeaders(DataRequestLevel.tenant);

    return this._http.post<IPushNotification[]>(
      environment.api_url.news_service_public +
        '/topic-push-notifications/query?' +
        'pageSize=' +
        pageSize +
        '&pageNumber=' +
        pageNumber,
      notificationQuery,
      { headers }
    );
  }
}
