import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { roleBasedGuard } from './guards/role-based.guard';
import { RedirectionByPlatform } from './resolvers/redirection-by-platform';

const routes: Routes = [

  {
    path: 'admin-login/:tenantCode',
    loadChildren: () =>
      import('./admin-login/admin-login.module').then(
        (m) => m.AdminLoginPageModule
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: '',
    resolve: {
      redirection: RedirectionByPlatform,
    },
    pathMatch: 'full',
    children: [],
  },
  {
    path: 'admin',
    canActivate: [roleBasedGuard],
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminPageModule),
  },
  {
    path: 'no-tenant-fallback',
    loadChildren: () => import('./no-tenant-fallback/no-tenant-fallback.module').then( m => m.NoTenantFallbackPageModule)
  },
  {
    path: '**',
    redirectTo: 'admin'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
