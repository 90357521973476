import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ICognitoAuthToken } from '../interfaces/i-cognito-auth-token';
import { HttpHeaders } from '@angular/common/http';
import { DataRequestLevel } from '../types/data-request-level';

@Injectable({
  providedIn: 'root',
})
export class RequestHeaderProviderService {

  public getXApiHeaders(
    level: DataRequestLevel,
    authorizationToken?: ICognitoAuthToken | null,
    organizationCode?: string
  ): HttpHeaders {
    let headers = new HttpHeaders();

    //X-Api-Key
    headers = headers.append('X-Api-Key', environment.x_api_headers.api_key);

    //X-Tenant-Code
    if (level === DataRequestLevel.tenant) {
      headers = headers.append(
        'X-Tenant-Code',
        this.XTenantCode
      );
    }

    //X-Organization-Code
    if (level === DataRequestLevel.organization && organizationCode) {
      headers = headers.append('X-Organization-Code', organizationCode);
    }

    //Authorization
    if (authorizationToken) {
      headers = headers.append(
        'Authorization',
        `Bearer ${authorizationToken.accessToken}`
      );
    }
    return headers;
  }

  private get XTenantCode(): string {
    return localStorage.getItem('tenant') || '';
  }

}
