<div class="image-container">
  <ion-img
    [src]="src"
    [alt]="alt"
    (ionImgWillLoad)="showLoading()"
    (ionImgDidLoad)="hideLoading()"
    (ionError)="handleError()"
    class="image"
    [style.--border-radius]="rounded ? (rounded + 'px') : '0px'"
  ></ion-img>
  <ion-spinner *ngIf="loading" [name]="spinnerName"></ion-spinner>
</div>
