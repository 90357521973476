import { Injectable } from '@angular/core';
import { AlertButton, AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private isAlertOpen = false;
  private buttons: AlertButton[] = [];
  private onDismissAction: () => void = () => { };

  constructor(
    private _alertController: AlertController
  ) { }

  public get IsAlertOpen(): boolean {
    return this.isAlertOpen;
  }

  public get Buttons(): AlertButton[] {
    return this.buttons;
  }

  public async presentAlert(header: string, subHeader: string, message: string, buttons: AlertButton[], onDismiss: () => void) {
    this.onDismissAction = onDismiss;
    const alert = await this._alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: buttons,
      backdropDismiss: false,
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    if (role === 'accept') {
      this.onDismissAction();
    }
  }
}
