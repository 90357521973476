import { Injectable } from '@angular/core';
import { DataRequestLevel } from '../types/data-request-level';

@Injectable({
  providedIn: 'root',
})
export class ApiRequestUrlHandlerService {
  public getDataLevelUrlPart(dataLevel: DataRequestLevel): string {
    switch (dataLevel) {
      case 'tenant':
        return '/tenant';
      case 'organization':
        return '/organizations';
      default:
        return '';
    }
  }
}
