import { Component, HostListener } from '@angular/core';
import { AuthenticationService } from 'src/app/global-services/authentication.service';
import { UserAccountService } from 'src/app/global-services/user-account.service';
import { VersionService } from 'src/app/global-services/version.service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { ModalController } from '@ionic/angular';
import { GlobalLoaderTriggerService } from 'src/app/global-services/global-loader-trigger.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  constructor(
    public versionService: VersionService,
    public userAccountService: UserAccountService,
    private _authenticationService: AuthenticationService,
    private _modalController: ModalController,
    private _globalLoaderTrigger: GlobalLoaderTriggerService
  ) {
    this.checkScreenSize();
  }

  isWideScreen?: boolean;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }

  private checkScreenSize() {
    this.isWideScreen = window.innerWidth > 768;
  }

  logout(): void {
    this._modalController
      .create({
        component: ConfirmationModalComponent,
        cssClass: 'confirmation-modal',
        componentProps: {
          message: 'Czy chcesz się wylogować?',
          title: 'Wyloguj z panelu',
        },
      })
      .then((modal) => {
        modal.present().then(() => {
          modal.onDidDismiss().then((result) => {
            if (result.data === 'confirm') {
              this._authenticationService.logoutWithAmplify();
            }
          });
        });
      });
  }

  reload(): void {
    this._modalController
      .create({
        component: ConfirmationModalComponent,
        cssClass: 'confirmation-modal',
        componentProps: {
          message: 'Czy chcesz przeładować aplikację?',
          title: 'Przeładuj aplikację',
        },
      })
      .then((modal) => {
        modal.present().then(() => {
          modal.onDidDismiss().then((result) => {
            if (result.data === 'confirm') {
              this._globalLoaderTrigger.showLoader();
              setTimeout(() => {
                window.location.reload();
              }, 500);
            }
          });
        });
      });
  }
}
